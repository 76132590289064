import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import ReactMarkdown from "react-markdown"

import { space } from "../../theme"
import Text from "../../components/text"
import { Link, IconLink, SimpleTextLink } from "../../components/link"
import Img from "../../components/img"

import { EventProps, Speaker, randomnumber } from "./utils"
import { M, renderers, colorKeys, IBG } from "./components"

export type Props = {
  data: EventProps
}

const SuperWrapper = styled.div`
  position: relative;
  display: grid;
`
const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  margin: 0 auto;
  ${up("tablet")} {
    grid-template-columns: 768px;
  }
  ${up("desktop")} {
    grid-template-columns: 1280px;
  }
`

const Container = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: ${space("small")};
  grid-row-gap: ${space("small")};
  padding: ${space("large")} ${space("small")};
  align-items: flex-start;
  justify-items: flex-start;
  max-width: 100%;
  margin: 0 auto;
  ${up("tablet")} {
    max-width: 768px;
    padding: ${space("large")} ${space("small")};
    grid-template-columns: auto 50px;
  }
  ${up("desktop")} {
    max-width: 900px;
    grid-row-gap: ${space("normal")};
    padding-bottom: 0;
  }
`

const StickyContainer = styled.div`
  position: relative;
  display: grid;
  grid-row: 2;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 0;
  ${up("tablet")} {
    grid-column: 2;
    grid-row: 1/3;
    position: sticky;
    top: 0;
    right: 0;
    width: 50px;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: ${space("normal")};
    justify-items: center;
    padding: ${space("small")} 0;
    padding-top: 8rem;
  }
`

const Hero = styled.div`
  grid-column: 1;
  grid-row: 1;
`
const MarkdownC = styled(M)`
  grid-column: 1;
`

const SpeakersContainer = styled.div`
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr;
  ${up("tablet")} {
    grid-template-columns: repeat(3, 1fr);
  }
  grid-gap: ${space("normal")};
`

const SpeakerContainer = styled.div<{ hasTitle: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  align-items: flex-start;
  justify-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  grid-template-rows: ${p =>
    p.hasTitle ? "auto auto auto auto;" : "auto auto auto;"};
`
const CallToAction = styled(Link)`
  grid-column: 1;
  justify-self: center;
`
const BlogImg = styled(Img)`
  grid-column: 1;
  position: relative;
`
const B = styled.span`
  position: relative;
  display: grid;
  width: 100%;
  justify-items: center;
  margin-top: 0;
  margin-bottom: 2rem;
  img {
    position: relative;
    top: 10px;
    margin: 0 auto;
    z-index: 2;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    ${up("tablet")} {
      top: 20px;
    }
  }
`

const Speakers: React.FC<{ speakers: Speaker[] }> = ({ speakers }) => {
  return (
    <SpeakersContainer>
      {speakers.map(({ name, title, job, image, link }, idx) => (
        <SpeakerContainer key={`speaker_${idx}`} hasTitle={!!title}>
          <Img
            src={{
              default: image.src,
              mobile: `${image.src}?fit=fillmax&h=250&max-w=768`,
              tablet: `${image.src}?fit=fillmax&h=680&max-w=768`,
              desktop: `${image.src}?fit=fillmax&h=680&max-w=1280`,
            }}
            alt={image.alt}
            mw="100%"
            mh="250px"
            tw="100%"
            th="250px"
            dw="100%"
            dh="250px"
            fit="cover"
          />
          <Text type="h4" weight="bold">
            {name}
          </Text>
          {title && (
            <Text type="h5" weight="bold">
              {title}
            </Text>
          )}
          {link ? (
            <SimpleTextLink
              align="left"
              alwaysUnderlined
              hoverColor="blue"
              to={link.to}
            >
              {job}
            </SimpleTextLink>
          ) : (
            <Text align="left">{job}</Text>
          )}
        </SpeakerContainer>
      ))}
    </SpeakersContainer>
  )
}

const EventPost: React.FC<Props> = ({
  data: { title, content, published, image, childrenSpeaker, registerLink },
}) => {
  const [url, setUrl] = React.useState("")
  const baseTweet = `Check out this event from @paybase ${title}`
  React.useEffect(() => {
    if (window) setUrl(window.location.toString())
  }, [])
  return (
    <SuperWrapper>
      <Wrapper>
        <Container>
          <StickyContainer>
            <IconLink
              hasPadding
              icon="twitter"
              hoverColor="blue"
              bg="transparent"
              shadow="transparent"
              to={`https://twitter.com/share?url=${url}&amp;text=${baseTweet}&amp;hashtags=Paybase,Event,FoundersWorkshop`}
            />
            <IconLink
              hasPadding
              icon="facebook"
              hoverColor="blue"
              bg="transparent"
              shadow="transparent"
              to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                url
              )}`}
            />
            <IconLink
              hasPadding
              icon="linkedin"
              hoverColor="blue"
              bg="transparent"
              shadow="transparent"
              to={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
                url
              )}`}
            />
          </StickyContainer>
          <Hero>
            <Text type="headline">{title}</Text>
            <Text type="h5" color="grey">
              {new Date(published).toLocaleDateString("en-GB", {
                // @ts-ignore
                dateStyle: "medium",
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </Text>
          </Hero>
          <B>
            <BlogImg
              src={{
                default: image.src,
                mobile: `${image.src}?fit=fillmax&h=250&max-w=768`,
                tablet: `${image.src}?fit=fillmax&h=680&max-w=768`,
                desktop: `${image.src}?fit=fillmax&h=680&max-w=1280`,
              }}
              alt={image.alt}
              mw="95%"
              mh="auto"
              tw="93.5%"
              th="auto"
              dw="95%"
              dh="auto"
              fit="cover"
            />
            <IBG bg={colorKeys[randomnumber(0, colorKeys.length - 1)]} />
            <IBG
              right
              bg={colorKeys[randomnumber(0, colorKeys.length - 1)]}
              top
            />
          </B>
          <CallToAction to={registerLink.to}>{registerLink.copy}</CallToAction>
          <MarkdownC>
            <ReactMarkdown
              source={content}
              escapeHtml={false}
              renderers={renderers}
            />
          </MarkdownC>
          <CallToAction to={registerLink.to}>{registerLink.copy}</CallToAction>
          <Speakers speakers={childrenSpeaker} />
        </Container>
      </Wrapper>
    </SuperWrapper>
  )
}

export default EventPost
