import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Text from "../../components/text"
import Img from "../../components/img"
import { TextLink } from "../../components/link"
import theme, { getColor, getBg, color, space, text } from "../../theme"
import { randomnumber } from "./utils"

export const colorKeys: ColorKeys[] = [
  "blue",
  "yellow",
  "lightblue",
  "purple",
  "lavender",
  "green",
  "darkgreen",
  "rose",
  "red",
]
export const strippedColorKeys: ColorKeys[] = [
  "blue",
  "purple",
  "darkgreen",
  "rose",
  "red",
]

export const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  margin: 0 auto;
  padding: 0 2rem;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`
export const CardContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto auto auto;
  grid-gap: ${space("small")};
  align-items: flex-start;
  justify-items: flex-start;
  padding: ${space("small")} ${space("normal")};
`

export const Svg = styled.svg<{ isLeft?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(${p => (p.isLeft ? 1 : -1)});
  pointer-events: none;
`

export const CardVPipe: React.FC<{ x: number; c: ColorKeys }> = ({
  x,
  c,
  ...props
}) => (
  <Svg {...props}>
    <rect fill={theme.colors[c]} x={x} y="0" width="30" height="90" />
  </Svg>
)
export const CardHPipe: React.FC<{
  y: number
  c: ColorKeys
  isLeft: boolean
}> = ({ y, c, ...props }) => (
  <Svg {...props}>
    <rect fill={theme.colors[c]} x="0" y={y} width="90" height="30" />
  </Svg>
)

export const TagContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 8px;
  grid-row-gap: 4px;
`
export const Tag = styled(Text)<{ c: ColorKeys }>`
  padding: 4px 8px;
  background-color: ${p => color(p.c)};
  border-radius: 12px;
`

export const Author = styled.span`
  font-weight: bold;
  color: ${getColor};
`
export const Excerpt = styled(Text)`
  word-break: break-word;
`

export const M = styled.div`
  margin-top: 0;
  position: relative;
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  ${text("body")};
  color: ${color("black")};

  ${up("tablet")} {
    margin-top: 0;
  }
  ${up("desktop")} {
    padding: 0 ${space("small")};
  }

  p,
  blockquote,
  ul,
  ol,
  dl,
  table,
  pre {
    ${text("body")};
    margin: 15px 0;
    text-align: justify;
  }

  ul,
  ol {
    ${text("body")};
    list-style: circle;
    padding-left: 30px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: ${space("normal")};
    margin-bottom: ${space("normal")};
  }

  h1 {
    ${text("h4")};
    border-bottom: 1px solid #ddd;
    color: #000;
  }

  h2 {
    ${text("h4")};
    border-bottom: 1px solid #eee;
    color: #000;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1.2em;
  }

  h5 {
    ${text("h4")};
    font-size: 1.4em;
  }

  h6 {
    ${text("h4")};
    font-size: 1.6em;
  }
  code {
    background-color: #f8f8f8;
    border-radius: 3px;
    border: 1px solid #ddd;
    font-family: Consolas, "Liberation Mono", Courier, monospace;
    font-size: 12px;
    margin: 0 2px;
    padding: 0 5px;
    white-space: pre;
  }
`

export const IBG = styled.span<{
  right?: boolean
  top?: boolean
  bg: ColorKeys
}>`
  position: absolute;
  top: ${p => (p.top ? 0 : null)};
  bottom: ${p => (p.top ? null : "-20px")};
  left: ${p => (p.right ? null : 0)};
  right: ${p => (p.right ? 0 : null)};
  width: 120px;
  height: 120px;
  background-color: ${getBg};
  z-index: 0;
  ${up("tablet")} {
    bottom: ${p => (p.top ? null : "-40px")};
  }
`

export const B = styled.span`
  position: relative;
  display: grid;
  width: 100%;
  justify-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
  img {
    position: relative;
    top: 10px;
    margin: 0 auto;
    z-index: 2;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    ${up("tablet")} {
      top: 20px;
    }
  }
`

const CustomImg = styled(Img)`
  min-height: 100px;
`

export const renderers = {
  image: (props: any) => {
    return (
      <B>
        <CustomImg
          {...props}
          fit="cover"
          alt="placeholder"
          mw="94%"
          mh="auto"
          tw="94%"
          th="auto"
          dw="95%"
          dh="auto"
        />
        <IBG top bg={colorKeys[randomnumber(0, colorKeys.length - 1)]} />
        <IBG right bg={colorKeys[randomnumber(0, colorKeys.length - 1)]} />
      </B>
    )
  },
  link: ({ href, to, ...props }: any) => (
    <TextLink
      color="blue"
      hoverColor={
        strippedColorKeys[randomnumber(0, strippedColorKeys.length - 1)]
      }
      to={to || href}
      {...props}
    />
  ),
}
