import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"
import EventPost from "../blocks/Event/EventPost"
import { EventProps } from "../blocks/Event/utils"

const EventPostTemplate: React.FC<{ pageContext: EventProps }> = ({
  pageContext,
}) => (
  <Layout>
    <Helmet>
      <title>Paybase - {pageContext.title}</title>
      <meta property="og:title" content={`Paybase - ${pageContext.title}`} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@paybase" />
      <meta name="twitter:creator" content="@paybase" />
      <meta
        property="og:url"
        content={`https://paybase.io/${pageContext.slug}`}
      />
      <meta property="og:title" content={`${pageContext.title}`} />
      <meta property="og:description" content={pageContext.excerpt} />
      <meta
        property="og:image"
        content={
          typeof pageContext.image.src === "string"
            ? pageContext.image.src
            : pageContext.image.src.default
        }
      />
      <meta
        property="twitter:image"
        content={
          typeof pageContext.image.src === "string"
            ? pageContext.image.src
            : pageContext.image.src.default
        }
      />
    </Helmet>
    <EventPost data={pageContext} />
  </Layout>
)

export default EventPostTemplate
